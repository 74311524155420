import React from 'react';
import {Modal, Button} from 'antd';

const Delete = ({gift, onClose, onSubmit}: any): JSX.Element => {
    return (
        <Modal
            visible={true}
            closable={false}
            title="Удаление пожелания"
            footer={[
                <Button key="1" color="secondary" className="custom-btn custom-btn__cancel" onClick={onClose}>
                    Отменить
                </Button>,
                <Button key="2" color="danger" className="custom-btn custom-btn__remove" onClick={onSubmit}>
                    Удалить
                </Button>,
            ]}
        >
            <div>
                {' '}
                Если вы нажмете кнопку <strong>Удалить подарок для: {gift.child.name}</strong>, все данные сотрутся.
                <br />
                Вы уверенны, что хотите сделать?
            </div>
        </Modal>
    );
};

export default Delete;
