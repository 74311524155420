import axios, {AxiosResponse} from 'axios';

import {Gift, PersonWhoBookedGift, GiftAttachment} from 'types/index';
import {GIFT_STATUS_ALL, GIFT_STATUS_NEW} from 'constants/statuses';

const restApi = axios.create({baseURL: '/api'});

interface PostAttachmentParams {
    giftID: string;
    attachment: File;
    attachmentType: string;
}

interface DeleteAttachmentParams {
    giftID: string;
    attachmentName: string;
    attachmentType: string;
}

type Role = 'admin' | 'guest';

export const adminLogin = (params: {username: string; password: string}): Promise<AxiosResponse<any>> => {
    return restApi.post(`/admin/login`, params);
};

export const adminLogOut = (): Promise<AxiosResponse<any>> => {
    return restApi.post(`/admin/logout`);
};

export const fetchListGiftsByAdmin = (params: any): Promise<AxiosResponse<{items: Gift[]; pages: number}>> => {
    return restApi.get(`/admin/gifts`, {
        params: {
            ...params,
            status: params.status === GIFT_STATUS_ALL || params.status === GIFT_STATUS_NEW ? undefined : params.status,
            approved: params.status === GIFT_STATUS_NEW ? 0 : undefined,
        },
    });
};

export const postGiftAttachmentByAdmin = (params: PostAttachmentParams): Promise<GiftAttachment> => {
    const {giftID, attachment, attachmentType} = params;
    const formData = new FormData();
    formData.append('attachments', attachment);
    return restApi
        .post(`/admin/gifts/attachments/${giftID}/${attachmentType}`, formData, {
            headers: {'Content-Type': 'multipart/form-data'},
        })
        .then(({data}) => data)
        .catch(error => console.error('Error on post gift attachment: ', error));
};

export const deleteGiftAttachmentByAdmin = (params: DeleteAttachmentParams): Promise<GiftAttachment> => {
    const {giftID, attachmentName, attachmentType} = params;
    return restApi
        .delete(`/admin/gifts/${giftID}/attachments/${attachmentType}/${attachmentName}`)
        .then(({data}) => data)
        .catch(error => console.error('Error on delete gift attachment by admin: ', error));
};

export const createGiftByAdmin = (gift: Gift): Promise<AxiosResponse<Gift>> => {
    return restApi.post(`/admin/gifts`, gift);
};

export const deleteGiftByAdmin = (gift: Gift): Promise<Gift[]> => {
    return restApi.delete(`/admin/gifts/${gift._id}`);
};

export const updateGiftByAdmin = (gift: Gift): Promise<AxiosResponse<Gift>> => {
    return restApi.put(`/admin/gifts/${gift._id}`, gift);
};

export const createGiftByGuest = (gift: Gift): Promise<AxiosResponse<Gift>> => {
    return restApi.post(`/guest/gifts`, gift);
};

export const postGiftAttachmentByGuest = (params: PostAttachmentParams): Promise<GiftAttachment> => {
    const {giftID, attachment, attachmentType} = params;
    const formData = new FormData();
    formData.append('attachments', attachment);
    return restApi
        .post(`/guest/gifts/attachments/${giftID}/${attachmentType}`, formData, {
            headers: {'Content-Type': 'multipart/form-data'},
        })
        .then(({data}) => data)
        .catch(error => console.error('Error on post gift attachment by guest: ', error));
};

export const fetchListGiftsByGuest = (params: any): Promise<AxiosResponse<{items: Gift[]; pages: number}>> => {
    return restApi.get(`/guest/gifts`, {
        params: {...params, status: params.status === GIFT_STATUS_ALL ? undefined : params.status},
    });
};

export const bookGiftByGuest = (gift: Gift, personWhoBooked: PersonWhoBookedGift): Promise<AxiosResponse> => {
    return restApi.post(`/guest/gifts/book/${gift._id}`, {
        name: personWhoBooked.name,
        phone: personWhoBooked.phone,
        comment: personWhoBooked.comment,
    });
};

export default restApi;
