const handler = {
    listener: null,
    get() {
        return this.listener;
    },
    set(func: any) {
        this.listener = func;
    },
    remove() {
        this.listener = null;
    },
};

export default handler;
