import isFunction from 'lodash/isFunction';

import {adminLogin, adminLogOut} from 'services/api';

import * as types from 'app/actions/actionTypes';

import handler from 'components/modals/handler';

export function showLoaderSpinner() {
    return {
        type: types.SHOW_LOADER_SPINNER,
    };
}

export function hideLoaderSpinner() {
    return {
        type: types.HIDE_LOADER_SPINNER,
    };
}

export function showModal(params: any) {
    const {name, mode, onSubmit, closeBeforeSubmit, data} = params;

    if (onSubmit && isFunction(onSubmit)) {
        handler.set(onSubmit);
    }

    return {
        type: types.SHOW_MODAL,
        payload: {
            name,
            mode,
            data,
            closeBeforeSubmit,
        },
    };
}

export function hideModal() {
    handler.remove();
    return {
        type: types.HIDE_MODAL,
    };
}

export function login({username, password}: any): any {
    return function(dispatch: any): any {
        dispatch(showLoaderSpinner());
        adminLogin({username, password})
            .then(() => {
                dispatch({
                    type: types.USER_LOGGED_IN,
                });
            })
            .catch(error => {
                console.warn('Admin login error: ', error);
                dispatch({
                    type: types.USER_LOGGED_IN_ERROR,
                    payload: 'Incorrect user name or password',
                });
            })
            .finally(() => dispatch(hideLoaderSpinner()));
    };
}

export function logOut() {
    return function(dispatch: any) {
        dispatch(showLoaderSpinner());
        adminLogOut()
            .catch(error => {
                console.warn('logout error: ', error);
            })
            .finally(() => {
                dispatch({
                    type: types.USER_LOGGED_OUT,
                });
                dispatch(hideLoaderSpinner());
            });
    };
}
