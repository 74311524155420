export const loadFromLocalStorage = key => {
    try {
        const data = window.localStorage.getItem(key);
        if (data === null) {
            return undefined;
        }
        return JSON.parse(data);
    } catch (e) {
        return undefined;
    }
};

export const saveToLocalStorage = (key, data) => {
    try {
        const json = JSON.stringify(data);
        window.localStorage.setItem(key, json);
    } catch (e) {}
};

export const getPathToPhoto = pathToFile => {
    if (!pathToFile) {
        return undefined;
    }
    return `${process.env.PUBLIC_URL}/${pathToFile}`;
};

export const isUploadedFile = file => {
    return file && file instanceof File;
};
