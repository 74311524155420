import React from 'react';
import {Drawer} from 'antd/lib';

import Form from '../Form';

const Update = ({gift, isAdmin, mode, onClose, onSubmit}: any): JSX.Element => {
    const initialValues = {
        ...gift,
        addedAttachments: {avatars: [], wishes: [], documents: [], reports: []},
        removedAttachments: {avatars: [], wishes: [], documents: [], reports: []},
    };
    return (
        <Drawer title="Редактирование" width={720} visible={true} bodyStyle={{paddingBottom: 80}} closable={false}>
            {/*
            // @ts-ignore */}
            <Form mode={mode} isAdmin={isAdmin} initialValues={initialValues} onClose={onClose} onSubmit={onSubmit} />
        </Drawer>
    );
};

export default Update;
