import omit from 'lodash/omit';

import {Gift} from 'types/index';

import * as appActions from 'app/actions';
import {
    fetchListGiftsByAdmin,
    updateGiftByAdmin,
    deleteGiftByAdmin,
    postGiftAttachmentByAdmin,
    deleteGiftAttachmentByAdmin,
    createGiftByAdmin,
} from 'services/api';
import * as statuses from 'constants/statuses';
import * as modalNames from 'constants/modals';
import * as types from '../actionTypes';
import {SearchParams} from '../types';
import {findGiftByID} from '../selectors';

type AttachmentsData = {
    avatars: any[];
    wishes: any[];
    documents: any[];
    reports: any[];
};

const isArrayEmpty = (arr: any[]): boolean => arr.length === 0;

const isExistsAttachments = (data: AttachmentsData): boolean => {
    return (
        !isArrayEmpty(data.avatars) ||
        !isArrayEmpty(data.wishes) ||
        !isArrayEmpty(data.documents) ||
        !isArrayEmpty(data.reports)
    );
};

const addAttachmentsToGift = async (giftID: string, attachments: AttachmentsData) => {
    const requests = Object.entries(attachments).flatMap(([attachmentType, addedAttachments]) => {
        return addedAttachments.map(attachment => {
            return postGiftAttachmentByAdmin({giftID, attachment: attachment.file, attachmentType}).then(
                postedAttachment => {
                    return {attachment: postedAttachment, attachmentType};
                },
            );
        });
    });
    const postedAttachments = await Promise.all(requests);
    return postedAttachments.reduce((result: any, postedAttachment) => {
        const isCorrectPostedAttachment = postedAttachment.attachment && postedAttachment.attachmentType;
        if (isCorrectPostedAttachment && !result[postedAttachment.attachmentType]) {
            result[postedAttachment.attachmentType] = [postedAttachment.attachment];
        } else if (isCorrectPostedAttachment && result[postedAttachment.attachmentType]) {
            result[postedAttachment.attachmentType] = [
                ...result[postedAttachment.attachmentType],
                postedAttachment.attachment,
            ];
        }
        return result;
    }, {});
};

const removeAttachmentsFromGift = (giftID: string, attachments: AttachmentsData) => {
    const requests = Object.entries(attachments).flatMap(([attachmentType, removedAttachments]) => {
        return removedAttachments.map(attachment =>
            deleteGiftAttachmentByAdmin({giftID, attachmentName: attachment.file.name, attachmentType}),
        );
    });
    return Promise.all(requests);
};

const showConfirmChangeStatusToFreeModal = ({onConfirm}: {onConfirm: any}) => {
    return function(dispatch: any) {
        const params = {
            name: modalNames.CONFIRM_CHANGE_STATUS_TO_FREE_MODAL,
            data: {},
            onSubmit: onConfirm,
        };
        dispatch(appActions.showModal(params));
    };
};

interface FetchGiftsParams {
    searchParams: SearchParams;
    pagination: {page: number; size: number};
}

export function fetchGifts({searchParams, pagination}: FetchGiftsParams): any {
    return function(dispatch: any) {
        dispatch(appActions.showLoaderSpinner());
        fetchListGiftsByAdmin({
            page: pagination.page,
            size: pagination.size,
            month: searchParams.month,
            status: searchParams.status,
        })
            .then(response => {
                dispatch({
                    type: types.LIST_GIFTS_RECEIVED,
                    payload: {
                        gifts: response.data.items,
                        pagination: {page: pagination.page, size: pagination.size, pagesCount: response.data.pages},
                    },
                });
            })
            .catch(error => {
                console.warn('Error on fetch gifts for admin role: ', error);
            })
            .finally(() => dispatch(appActions.hideLoaderSpinner()));
    };
}

export function refreshGiftsList(currentStatus: string): any {
    return function(dispatch: any) {
        dispatch(appActions.showLoaderSpinner());
        const defaultPagination = {page: 0, size: 20};
        fetchListGiftsByAdmin({
            page: defaultPagination.page,
            size: defaultPagination.size,
            status: currentStatus,
        })
            .then(response => {
                dispatch({
                    type: types.LIST_GIFTS_RECEIVED,
                    payload: {
                        gifts: response.data.items,
                        pagination: {
                            page: defaultPagination.page,
                            size: defaultPagination.size,
                            pagesCount: response.data.pages,
                        },
                    },
                });
            })
            .catch(error => {
                console.warn('Error on fetch gifts for admin role: ', error);
            })
            .finally(() => dispatch(appActions.hideLoaderSpinner()));
    };
}

export function createGift(createParams: Gift): any {
    return async function(dispatch: any) {
        dispatch(appActions.showLoaderSpinner());

        const createdGift = await createGiftByAdmin(createParams)
            .then(response => response.data)
            .catch(error => {
                dispatch(appActions.showModal({name: modalNames.GIFT_CREATION_ERROR_MODAL}));
                console.warn('Error on create gift by admin role: ', error);
            })
            .finally(() => dispatch(appActions.hideLoaderSpinner()));

        if (createdGift && isExistsAttachments(createParams.attachments)) {
            createdGift.attachments = await addAttachmentsToGift(createdGift._id, createParams.attachments);
        }

        dispatch({
            type: types.GIFT_CREATED,
            payload: createdGift,
        });

        dispatch(appActions.hideLoaderSpinner());
    };
}

export function editGift(giftData: any): any {
    return async function(dispatch: any, getState: any) {
        const state = getState();
        const giftOldData = findGiftByID(state, giftData._id);
        const isNeedAddAttachments = isExistsAttachments(giftData.addedAttachments);
        const isNeedRemoveAttachments = isExistsAttachments(giftData.removedAttachments);
        const isGiftPrevStatusBooked = giftOldData?.status === statuses.GIFT_STATUS_BOOKED;
        const updateSelectedGift = (dataForUpdate: any): void => {
            dispatch(appActions.showLoaderSpinner());
            updateGiftByAdmin(dataForUpdate)
                .then(response => {
                    dispatch({
                        type: types.SELECTED_GIFT_UPDATED,
                        payload: response.data,
                    });
                })
                .catch(error => {
                    dispatch(appActions.showModal({name: modalNames.GIFT_UPDATE_ERROR_MODAL}));
                    console.warn('Error on update gift: ', error);
                })
                .finally(() => dispatch(appActions.hideLoaderSpinner()));
        };

        if (isNeedAddAttachments) {
            await addAttachmentsToGift(giftData._id, giftData.addedAttachments);
        }

        if (isNeedRemoveAttachments) {
            await removeAttachmentsFromGift(giftData._id, giftData.removedAttachments);
        }

        let putData = omit(giftData, ['addedAttachments', 'removedAttachments']) as Gift;

        if (isGiftPrevStatusBooked && giftData.status === statuses.GIFT_STATUS_FREE) {
            putData = omit(putData, ['bookedPerson']) as Gift;
            const confirmParams = {
                onConfirm: () => updateSelectedGift(putData),
            };
            dispatch(showConfirmChangeStatusToFreeModal(confirmParams));
        } else {
            updateSelectedGift(putData);
        }
    };
}

export function deleteGift(gift: Gift): any {
    return function(dispatch: any) {
        dispatch(appActions.showLoaderSpinner());
        deleteGiftByAdmin(gift)
            .then(() => {
                dispatch({
                    type: types.SELECTED_GIFT_DELETED,
                    payload: gift,
                });
            })
            .catch(error => {
                console.warn('Error on delete gift: ', error);
            })
            .finally(() => dispatch(appActions.hideLoaderSpinner()));
    };
}
