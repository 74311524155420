import {ModalNamesType} from 'constants/modals';
import * as types from 'app/actions/actionTypes';

interface CommonDataState {
    auth: {
        isLoggedIn: boolean;
    };
    modalData: {modalName: ModalNamesType; modalData: any} | undefined;
    showLoaderSpinner: boolean;
}

const getDefaultState = (initAuthData: any): CommonDataState => {
    const isLoggedIn = Boolean(initAuthData?.isLoggedIn);
    const defaultState: CommonDataState = {
        auth: {
            isLoggedIn,
        },
        modalData: undefined,
        showLoaderSpinner: false,
    };
    return defaultState;
};

export default function wrapper(initAuthData: any) {
    const reducer = (state = getDefaultState(initAuthData), action: types.AppActionTypes): CommonDataState => {
        switch (action.type) {
            case types.USER_LOGGED_IN: {
                return {
                    ...state,
                    auth: {
                        isLoggedIn: true,
                    },
                };
            }

            case types.USER_LOGGED_OUT: {
                return {
                    ...state,
                    auth: {
                        isLoggedIn: false,
                    },
                };
            }

            case types.SHOW_LOADER_SPINNER: {
                return {
                    ...state,
                    showLoaderSpinner: true,
                };
            }

            case types.HIDE_LOADER_SPINNER: {
                return {
                    ...state,
                    showLoaderSpinner: false,
                };
            }

            case types.SHOW_MODAL: {
                return {
                    ...state,
                    modalData: action.payload,
                };
            }

            case types.HIDE_MODAL: {
                return {
                    ...state,
                    modalData: undefined,
                };
            }

            default:
                return state;
        }
    };

    return reducer;
}
