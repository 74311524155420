import {createGiftByGuest, postGiftAttachmentByGuest} from 'services/api';
import * as modalNames from 'constants/modals';
import * as appActions from 'app/actions';
import {Gift} from 'types';

type AttachmentsData = {
    avatars: any[];
    wishes: any[];
    documents: any[];
};

const isArrayEmpty = (arr: any[]): boolean => arr.length === 0;

const isExistsAddedAttachments = (data: AttachmentsData): boolean => {
    return !isArrayEmpty(data.avatars) || !isArrayEmpty(data.wishes) || !isArrayEmpty(data.documents);
};

const addAttachmentsToGift = (giftID: string, attachments: AttachmentsData): Promise<any> => {
    const requests = Object.entries(attachments).flatMap(([attachmentType, addedFiles]) => {
        return addedFiles.map(attachment =>
            postGiftAttachmentByGuest({giftID, attachment: attachment.file, attachmentType}),
        );
    });
    return Promise.all(requests);
};

export function createGift(createParams: Gift): any {
    return async function(dispatch: any) {
        dispatch(appActions.showLoaderSpinner());

        const createdGift = await createGiftByGuest(createParams)
            .then(response => response.data)
            .catch(error => {
                dispatch(appActions.showModal({name: modalNames.GIFT_CREATION_ERROR_MODAL}));
                console.warn('Error on create gift by guest role: ', error);
            })
            .finally(() => dispatch(appActions.hideLoaderSpinner()));

        if (createdGift && isExistsAddedAttachments(createParams.attachments)) {
            await addAttachmentsToGift(createdGift._id, createParams.attachments);
        }

        if (createdGift) {
            dispatch(appActions.showModal({name: modalNames.GIFT_CREATION_SUCCESS_MODAL}));
        }

        dispatch(appActions.hideLoaderSpinner());
    };
}
