import React, {useMemo} from 'react';
import {Select} from 'antd';

import * as statuses from 'constants/statuses';

const statusesOptions = [
    {value: statuses.GIFT_STATUS_ALL, label: 'Все статусы'},
    {value: statuses.GIFT_STATUS_NEW, label: 'Новая заявка'},
    {value: statuses.GIFT_STATUS_FREE, label: 'Свободен'},
    {value: statuses.GIFT_STATUS_BOOKED, label: 'Забронирован'},
    {value: statuses.GIFT_STATUS_CONFIRMED, label: 'Подтвержден'},
    {value: statuses.GIFT_STATUS_DONE, label: 'Исполнен'},
];

const StatusesDropDown = ({value, onChange}: any): JSX.Element => {
    const selectedOption = useMemo(() => {
        return statusesOptions.find((item: any) => item.value === value);
    }, [value]);
    const handleChange = (selectedValue: any): void => {
        onChange(selectedValue);
    };

    return (
        <>
            <Select value={selectedOption?.value} onChange={handleChange} style={{width: 200}}>
                {statusesOptions.map((item: any, index: number) => (
                    <Select.Option key={index} value={item.value}>
                        {item.label}
                    </Select.Option>
                ))}
            </Select>
        </>
    );
};

export default StatusesDropDown;
