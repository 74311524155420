import React from 'react';
import {Modal, Button} from 'antd';

const ReservedGiftWarning = ({onSubmit}: any) => {
    return (
        <Modal
            visible={true}
            closable={false}
            title="Бронирование невозможно"
            footer={[
                <Button color="secondary" onClick={onSubmit}>
                    OK
                </Button>,
            ]}
        >
            <div>Этот подарок был забронирован другим человеком</div>
        </Modal>
    );
};

export default ReservedGiftWarning;
