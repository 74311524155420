import {Gift} from 'types/index';

import {SearchParams} from '../types';

export const LIST_GIFTS_RECEIVED = 'GIFTS_PAGE_LIST_GIFTS_RECEIVED' as const;
export const GIFT_CREATED = 'GIFTS_PAGE_GIFT_CRATED' as const;
export const SELECTED_GIFT_UPDATED = 'GIFTS_PAGE_SELECTED_GIFT_UPDATED' as const;
export const SELECTED_GIFT_DELETED = 'GIFTS_PAGE_SELECTED_GIFT_DELETED' as const;
export const SELECTED_GIFT_BOOKED = 'GIFTS_PAGE_SELECTED_GIFT_BOOKED' as const;
export const SELECTED_SEARCH_PARAMS_CHANGED = 'GIFTS_PAGE_SELECTED_SEARCH_PARAMS_CHANGED' as const;
export const SELECTED_SEARCH_PARAMS_CLEARED = 'GIFTS_PAGE_SELECTED_SEARCH_PARAMS_CLEARED' as const;
export const SELECTED_PAGINATION_CHANGED = 'GIFTS_PAGE_SELECTED_PAGINATION_CHANGED' as const;

interface GiftsReceived {
    type: typeof LIST_GIFTS_RECEIVED;
    payload: {gifts: Gift[]; pagination: {page: number; size: number; pagesCount: number}};
}

interface GiftCreated {
    type: typeof GIFT_CREATED;
    payload: Gift;
}

interface SelectedGiftUpdated {
    type: typeof SELECTED_GIFT_UPDATED;
    payload: Gift;
}

interface SelectedGiftDeleted {
    type: typeof SELECTED_GIFT_DELETED;
    payload: Gift;
}

interface SelectedGiftBooked {
    type: typeof SELECTED_GIFT_BOOKED;
    payload: Gift;
}

interface SelectedSearchParamsChanged {
    type: typeof SELECTED_SEARCH_PARAMS_CHANGED;
    payload: SearchParams;
}

interface SelectedSearchParamsCleared {
    type: typeof SELECTED_SEARCH_PARAMS_CLEARED;
}

interface SelectedPaginationChanged {
    type: typeof SELECTED_PAGINATION_CHANGED;
    payload: {nextPage: number};
}

export type GiftsActionTypes =
    | GiftsReceived
    | GiftCreated
    | SelectedGiftUpdated
    | SelectedGiftDeleted
    | SelectedGiftBooked
    | SelectedSearchParamsChanged
    | SelectedSearchParamsCleared
    | SelectedPaginationChanged;
