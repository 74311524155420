import React from 'react';
import BreadCrumbs from './components/BreadCrumbs';

import styles from './requisites.module.scss';

const Requisites = (): JSX.Element | null => {
    return (
        <div className={styles.item}>
            <BreadCrumbs />
            <div className={styles.main}>
                <div className={styles.heading}>Реквизиты фонда</div>
                <div className="requisites-privat">
                    <h3>
                        <strong>ПриватБанк</strong>
                    </h3>
                    <div className="requisites-privat__card">
                        <strong>5363 5420 1719 9317</strong>
                    </div>
                    <p>(Карта оформлена на Ткаченко Валентину Валентиновну, основательницу фонда)</p>
                </div>
                <div className="requisites-description">
                    <h2>
                        Областной благотворительный фонд “Доброта и Доверие” <br />
                        неприбыльная организация
                    </h2>
                    <p>
                        <strong>
                            Код ЕДРПОУ 38790010
                            <br />
                            р/с UA523281680000000026002620821
                            <br />
                            МФО 328168 в<br />
                            {/* eslint-disable-next-line react/no-unescaped-entities */}
                            ПАТ "МТБ БАНК"
                        </strong>
                    </p>
                    <p>
                        <strong>Назначение платежа:</strong> Благотворительный взнос
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Requisites;
