import React, {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {Button} from 'antd';

import useShowModalsActions from 'hooks/useShowModalsActions';

import {Gift} from 'types/index';

import {Link} from 'react-router-dom';
import * as actions from './actions';
import styles from './main.module.scss';

const Index = (): JSX.Element | null => {
    const {showCreateGiftModal} = useShowModalsActions();
    const dispatch = useDispatch();
    const showCreateGiftModalHandler = useCallback(() => {
        showCreateGiftModal((gift: Gift): void => {
            dispatch(actions.createGift(gift));
        });
    }, [dispatch, showCreateGiftModal]);
    return (
        <div className={styles.wrap}>
            <div className={styles.item}>
                <div className="description">
                    <div className="description__title">Страна Фей</div>
                    Проект, где Вы сможете стать настоящим Волшебником, осуществив мечты детей с особыми потребностями,{' '}
                    сирот, малоимущих, онкобольных деток. И подарить такому ребенку незабываемый сказочный праздник в{' '}
                    его <strong>День Рождения!</strong>
                </div>
                <div className="action-wrap">
                    <div className="action">
                        <div className="action__request">
                            <p>
                                Для того, чтобы принять участие, Вам необходимо подать заявку, заполнить анкету и
                                прикрепить документы, подтверждающие социальный статус.
                            </p>
                            <Button onClick={showCreateGiftModalHandler} className="custom-btn custom-btn__request">
                                ПОДАТЬ ЗАЯВКУ
                            </Button>
                        </div>
                        <div className="action__gifts">
                            <p>
                                На сайте размещены мечты детей в их День Рождения, которого они ждут с огромным трепетом{' '}
                                каждый год! Станьте феей или волшебником ребенка.
                            </p>
                            <Link to="/gifts" className="ant-btn custom-btn custom-btn__action ant-btn-primary">
                                СТАТЬ ВОЛШЕБНИКОМ
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Index;
