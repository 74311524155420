import React, {useMemo} from 'react';
import {Select} from 'antd';

const DropDown = ({field, options, setFieldValue}: any): JSX.Element => {
    const selectedValue = useMemo(() => {
        return options.find((item: any) => item.value === field.value)?.label;
    }, [field.value, options]);
    const handleChange = (value: any): void => {
        setFieldValue(field.name, value);
    };
    return (
        <Select value={selectedValue} onChange={handleChange} style={{width: 200}}>
            {options.map((item: any, index: number) => (
                <Select.Option key={index} value={item.value}>
                    {item.label}
                </Select.Option>
            ))}
        </Select>
    );
};

export default DropDown;
