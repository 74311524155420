import React from 'react';
import {getIn} from 'formik';
import {Radio} from 'antd';

interface OwnProps {
    field: any;
    form: any;
    options: {label: string; value: string}[];
}

const RadioInput = ({field, form, options}: OwnProps): JSX.Element | null => {
    const fieldName = field.name;
    const currentValue = getIn(form.values, fieldName);
    const handleChange = (selectedValue: any): void => {
        form.setFieldValue(fieldName, selectedValue);
    };
    const items = options.map(option => {
        return (
            <Radio
                key={option.value}
                value={option.value}
                checked={currentValue === option.value}
                onClick={() => handleChange(option.value)}
            >
                {option.label}
            </Radio>
        );
    });
    return <Radio.Group>{items}</Radio.Group>;
};

export default RadioInput;
