import {connectedRouterRedirect} from 'redux-auth-wrapper/history4/redirect';

import {isUserLoggedIn} from 'app/reducers/commonData/selectors';

export const userIsNotAuthenticatedRedir = connectedRouterRedirect({
    authenticatedSelector: state => !isUserLoggedIn(state),
    wrapperDisplayName: 'UserIsNotAuthenticated',
    redirectPath: () => '/admin',
    allowRedirectBack: false,
});

export const userIsAuthenticatedRedir = connectedRouterRedirect({
    authenticatedSelector: state => isUserLoggedIn(state),
    wrapperDisplayName: 'UserIsAuthenticated',
    redirectPath: '/login',
});
