import React from 'react';
import {Layout} from 'antd';

import Modals from 'components/modals';
import Router from 'router';

import styles from './main.module.scss';

const Main = (): JSX.Element => {
    return (
        <Layout.Content role="main" className={styles.main}>
            <div className={styles.content} />
            <Router />
            <Modals />
        </Layout.Content>
    );
};

export default Main;
