import React, {useState} from 'react';
import {Formik, Field, FieldArray} from 'formik/dist';
import {Form as AntdForm, Button} from 'antd/lib';
import * as Yup from 'yup';

import {Gift, GiftPhoto} from 'types/index';
import * as modes from 'constants/modes';

import FormTextInput from 'components/form/TextInput';
import DatePicker from 'components/form/DatePicker';
import FormTextArea from 'components/form/TextArea';
import DropDownSelect from 'components/form/DropDown';
import RadioInput from 'components/form/RadioInput';
import * as statuses from 'constants/statuses';
import * as socialStatuses from 'constants/socialStatuses';

import ListAttachments from './components/ListAttachments';
import ConfirmGiftSwitcher from './components/ApproveGiftSwitcher';
import ConfirmTermsOfConditionsCheckbox from './components/ConfirmTermsOfConditionsCheckbox';
import styles from './form.module.scss';

export interface InitialValues extends Gift {
    addedPhotos?: File[];
    removedPhotos?: GiftPhoto[];
}

interface FormProps {
    isAdmin: boolean;
    mode: 'create' | 'update';
    initialValues: InitialValues;
    onClose(): void;
    onSubmit(fields: any): void;
}

const parentTypes = [
    {label: 'Родитель', value: 'parent'},
    {label: 'Опекун', value: 'guardian'},
];

const statusesList = [
    {value: statuses.GIFT_STATUS_FREE, label: 'Свободен'},
    {value: statuses.GIFT_STATUS_BOOKED, label: 'Забронирован'},
    {value: statuses.GIFT_STATUS_CONFIRMED, label: 'Подтвержден'},
    {value: statuses.GIFT_STATUS_DONE, label: 'Исполнен'},
];

const socialStatusList = [
    {value: socialStatuses.DISABLED, label: 'ребенок с инвалидностью'},
    {value: socialStatuses.ORPHAN, label: 'сироты'},
    {value: socialStatuses.FATHER_DIED_ATO, label: 'отцы, погибшие в АТО (безвести пропали)'},
    {value: socialStatuses.LARGE_FAMILY, label: 'многодетные семьи'},
    {value: socialStatuses.LARGE_FAMILY_INCLUSION, label: 'многодетные семьи, где есть инклюзия'},
    {value: socialStatuses.CANCER, label: 'онкобольные дети'},
    {value: socialStatuses.MIGRANTS, label: 'переселенцы'},
    {value: socialStatuses.ATO_FAMILY, label: 'семьи АТО'},
    {value: socialStatuses.DIFFICULT_CIRCUMSTANCES, label: 'сложные жизненные обстоятельства'},
];

const FIELDS = {
    childName: 'child.name',
    childBirthDay: 'child.birthDay',
    childSocialStatus: 'child.socialStatus',
    childDiagnosis: 'child.diagnosis',
    childCity: 'child.city',
    parentType: 'parent.type',
    parentName: 'parent.name',
    parentPhone: 'parent.phone',
    parentSocialNetworkLink: 'parent.socialNetworkLink',
    description: 'description',
    attachmentAvatars: 'attachments.avatars',
    attachmentWishes: 'attachments.wishes',
    attachmentDocuments: 'attachments.documents',
    attachmentReports: 'attachments.reports',
    videoReportLink: 'videoReportLink',
    status: 'status',
    bookedPersonName: 'bookedPerson.name',
    bookedPersonPhone: 'bookedPerson.phone',
    bookedPersonComment: 'bookedPerson.comment',
    isApproved: 'isApproved',
};
const phoneRegExp = /^\+\d{2}\s\(\d{3}\)\s\d{3}\s\d{2}\s\d{2}$/;
const phoneMask = [
    '+',
    '3',
    '8',
    ' ',
    '(',
    /[0-9]/,
    /\d/,
    /\d/,
    ')',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
];

const validationSchema = Yup.object().shape({
    child: Yup.object().shape({
        name: Yup.string().required('Обязательное поле'),
        birthDay: Yup.string().required('Обязательное поле'),
        socialStatus: Yup.string().required('Обязательное поле'),
        city: Yup.string().required('Обязательное поле'),
    }),
    parent: Yup.object().shape({
        name: Yup.string().required('Обязательное поле'),
        phone: Yup.string()
            .required('Обязательное поле')
            .matches(phoneRegExp, 'Введите валидный номер'),
    }),
    description: Yup.string().required('Обязательное поле'),
    bookedPerson: Yup.object().shape({
        phone: Yup.string().matches(phoneRegExp, 'Введите валидный номер'),
    }),
});

const FormContent = ({formProps, isAdmin, mode, onClose}: any): JSX.Element => {
    const [isConfirmedTermsOfConditions, setConfirmTermsOfConditions] = useState(false);
    const selectedStatus = formProps.values[FIELDS.status];
    const selectedChild = formProps.values.child;
    const selectedChildSocialStatus = selectedChild?.socialStatus;
    const isApproved = formProps.values[FIELDS.isApproved];
    return (
        <AntdForm onSubmit={formProps.handleSubmit}>
            <div className={styles.scroll}>
                {!isAdmin && (
                    <div className={styles.amount}>
                        Сумма подарка от <strong>500 грн</strong> (выше по согласованию с организатором) или необходимая
                        вещь для ребенка.
                        <br />
                        Телефоны, планшеты и ноутбуки в список подарков не входят.
                        <br />
                        Мы не оплачиваем операции и реабилитации.
                    </div>
                )}
                {mode === modes.MODE_CREATE && <div>Заполните ваши данные, чтобы мы могли связаться с вами</div>}
                {mode === modes.MODE_UPDATE && !isApproved && (
                    <div className="not-approved-badge">заявка не потверждена</div>
                )}
                <AntdForm.Item label="Город проживания">
                    <Field maxLength={50} name={FIELDS.childCity} component={FormTextInput} placeholder="" />
                </AntdForm.Item>
                <AntdForm.Item label="Фамилия Имя ребенка">
                    <Field maxLength={50} name={FIELDS.childName} component={FormTextInput} placeholder="" />
                </AntdForm.Item>
                <AntdForm.Item label="Дата рождения ребенка">
                    <Field
                        maxLength={50}
                        name={FIELDS.childBirthDay}
                        component={DatePicker}
                        placeholder="выберите дату"
                    />
                </AntdForm.Item>
                <AntdForm.Item label="Социальный статус">
                    <Field
                        name={FIELDS.childSocialStatus}
                        component={DropDownSelect}
                        setFieldValue={formProps.setFieldValue}
                        options={socialStatusList}
                        placeholder=""
                    />
                </AntdForm.Item>
                {selectedChildSocialStatus === socialStatuses.DISABLED && (
                    <AntdForm.Item label="Полный диагноз ребенка">
                        <Field maxLength={50} name={FIELDS.childDiagnosis} component={FormTextInput} placeholder="" />
                    </AntdForm.Item>
                )}
                <AntdForm.Item>
                    <Field name={FIELDS.parentType} component={RadioInput} options={parentTypes} placeholder="" />
                </AntdForm.Item>
                <AntdForm.Item label="Фамилия Имя родителя / опекуна">
                    <Field maxLength={50} name={FIELDS.parentName} component={FormTextInput} placeholder="" />
                </AntdForm.Item>
                <AntdForm.Item label="Телефон родителя / опекуна">
                    <Field
                        maxLength={50}
                        name={FIELDS.parentPhone}
                        mask={phoneMask}
                        component={FormTextInput}
                        placeholder="+38 (___) ___ __ __"
                    />
                </AntdForm.Item>
                <AntdForm.Item label="Ссылка на социльную сеть (Facebook, Instagram)">
                    <Field
                        maxLength={50}
                        name={FIELDS.parentSocialNetworkLink}
                        component={FormTextInput}
                        placeholder=""
                    />
                </AntdForm.Item>
                <AntdForm.Item label="Пожелание">
                    <Field name={FIELDS.description} component={FormTextArea} placeholder="" maxLength={120} />
                </AntdForm.Item>
                <AntdForm.Item>
                    <FieldArray
                        name={FIELDS.attachmentAvatars}
                        render={(helpers: any) => {
                            return (
                                <ListAttachments
                                    {...helpers}
                                    mode={mode}
                                    attachmentName="avatars"
                                    uploadTitle="Загрузить фото ребенка"
                                />
                            );
                        }}
                    />
                </AntdForm.Item>
                <AntdForm.Item>
                    <FieldArray
                        name={FIELDS.attachmentWishes}
                        render={(helpers: any) => {
                            return (
                                <ListAttachments
                                    {...helpers}
                                    mode={mode}
                                    attachmentName="wishes"
                                    uploadTitle="Загрузить другие фотографии"
                                />
                            );
                        }}
                    />
                </AntdForm.Item>
                <AntdForm.Item>
                    <FieldArray
                        name={FIELDS.attachmentDocuments}
                        render={(helpers: any) => (
                            <ListAttachments
                                {...helpers}
                                mode={mode}
                                attachmentName="documents"
                                uploadTitle="Загрузить документы, подтверждающие социальный статус"
                            />
                        )}
                    />
                </AntdForm.Item>
                {mode === modes.MODE_UPDATE && (
                    <>
                        {selectedStatus !== statuses.GIFT_STATUS_FREE && (
                            <div>
                                <AntdForm.Item label="Фамилия Имя Исполнителя">
                                    <Field name={FIELDS.bookedPersonName} component={FormTextInput} placeholder="" />
                                </AntdForm.Item>
                                <AntdForm.Item label="Телефон Исполнителя">
                                    <Field
                                        maxLength={50}
                                        name={FIELDS.bookedPersonPhone}
                                        mask={phoneMask}
                                        component={FormTextInput}
                                        placeholder="+38 (___) ___ __ __"
                                    />
                                </AntdForm.Item>
                                <AntdForm.Item label="Комментарий, ссылка на профиль в соцсетях">
                                    <Field name={FIELDS.bookedPersonComment} component={FormTextArea} />
                                </AntdForm.Item>
                            </div>
                        )}
                        <AntdForm.Item>
                            <Field
                                name={FIELDS.status}
                                component={DropDownSelect}
                                setFieldValue={formProps.setFieldValue}
                                options={statusesList}
                                placeholder=""
                            />
                        </AntdForm.Item>

                        <AntdForm.Item>
                            <Field name={FIELDS.isApproved} component={ConfirmGiftSwitcher} placeholder="" />
                        </AntdForm.Item>

                        {selectedStatus === statuses.GIFT_STATUS_DONE && (
                            <>
                                <AntdForm.Item label="Отчет">
                                    <FieldArray
                                        name={FIELDS.attachmentReports}
                                        render={(helpers: any) => {
                                            return (
                                                <ListAttachments
                                                    {...helpers}
                                                    mode={mode}
                                                    attachmentName="reports"
                                                    uploadTitle="Загрузить фото для отчета"
                                                />
                                            );
                                        }}
                                    />
                                </AntdForm.Item>
                                <AntdForm.Item label="Ссылка на youtube видео">
                                    <Field
                                        maxLength={50}
                                        name={FIELDS.videoReportLink}
                                        component={FormTextInput}
                                        placeholder=""
                                    />
                                </AntdForm.Item>
                            </>
                        )}
                    </>
                )}

                {!isAdmin && (
                    <div className="сonfirm__terms_conditions">
                        <ConfirmTermsOfConditionsCheckbox
                            onChange={setConfirmTermsOfConditions}
                            checked={isConfirmedTermsOfConditions}
                        />
                    </div>
                )}

                <div className={styles.footer}>
                    <Button color="secondary" onClick={onClose} className="custom-btn custom-btn__cancel">
                        Отменить
                    </Button>
                    {isAdmin ? (
                        <Button disabled={!formProps.dirty} htmlType="submit" className="custom-btn custom-btn__action">
                            Сохранить
                        </Button>
                    ) : (
                        <Button
                            disabled={!formProps.dirty || !isConfirmedTermsOfConditions}
                            type="primary"
                            htmlType="submit"
                            className="custom-btn custom-btn__action"
                        >
                            Отправить
                        </Button>
                    )}
                </div>
            </div>
        </AntdForm>
    );
};

const Form = ({isAdmin, mode, onSubmit, initialValues, onClose}: FormProps): JSX.Element => (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {(props: any) => (
            <FormContent
                isAdmin={isAdmin}
                mode={mode}
                initialValues={initialValues}
                formProps={props}
                onClose={onClose}
            />
        )}
    </Formik>
);

export default Form;
