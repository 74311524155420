import {Switch} from 'antd/lib';
import React from 'react';

interface OwnProps {
    field: any;
    form: any;
}

const ApproveGiftSwitcher = ({field, form}: OwnProps): JSX.Element | null => {
    const isApproved = Boolean(form.values[field.name]);
    const confirmRequestText = isApproved ? <h5>Участие в акции</h5> : <h4>Подтвердите заявку на участие в акции</h4>;
    const onChangeHandler = (): void => {
        form.setFieldValue(field.name, !isApproved);
    };
    return (
        <div className="confirm__request">
            <div className="confirm__request_switch">
                {confirmRequestText}
                <Switch checked={isApproved} onChange={onChangeHandler} />
            </div>
            {!isApproved && (
                <p>
                    Пока вы не подтвердите завку на участие в акции, она не будет добавлена в список для бронирования.
                </p>
            )}
        </div>
    );
};

export default ApproveGiftSwitcher;
