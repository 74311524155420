import React from 'react';
import {connect} from 'react-redux';
import {Row, Col} from 'antd';

import * as appActions from 'app/actions';

import LoginForm from './LoginForm';

import styles from './login.module.scss';

interface OwnProps {
    login(fields: any): void;
}

const Login = ({login}: OwnProps) => {
    return (
        <Row type="flex" justify="center" className={styles.page}>
            <Col xs={16} md={6}>
                <h3>Login</h3>
                <LoginForm onSubmit={login} />
            </Col>
        </Row>
    );
};

export default connect(null, dispatch => ({
    login: (formFields: any) => {
        return dispatch(appActions.login(formFields));
    },
}))(Login);
