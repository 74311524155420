import {ModalNamesType} from 'constants/modals';

export const USER_LOGGED_IN = 'USER_LOGGED_IN' as const;
export const USER_LOGGED_OUT = 'USER_LOGGED_OUT' as const;
export const USER_LOGGED_IN_ERROR = 'USER_LOGGED_IN_ERROR' as const;

export const SHOW_LOADER_SPINNER = 'SHOW_LOADER_SPINNER' as const;
export const HIDE_LOADER_SPINNER = 'HIDE_LOADER_SPINNER' as const;

export const SHOW_MODAL = 'SHOW_MODAL' as const;
export const HIDE_MODAL = 'HIDE_MODAL' as const;

export interface UserLoggedIn {
    type: typeof USER_LOGGED_IN;
}

export interface UserLoggedOut {
    type: typeof USER_LOGGED_OUT;
}

export interface ShowModal {
    type: typeof SHOW_MODAL;
    payload: {modalName: ModalNamesType; modalData: any};
}

export interface HideModal {
    type: typeof HIDE_MODAL;
}

export interface ShowLoaderSpinner {
    type: typeof SHOW_LOADER_SPINNER;
}

export interface HideLoaderSpinner {
    type: typeof HIDE_LOADER_SPINNER;
}

export type AppActionTypes =
    | UserLoggedIn
    | UserLoggedOut
    | ShowModal
    | HideModal
    | ShowLoaderSpinner
    | HideLoaderSpinner;
