import React from 'react';
import {Icon} from 'antd';
import lodashGet from 'lodash/get';

import * as modes from 'constants/modes';
import {getPathToPhoto} from 'utils/index';

import AttachmentItem from './AttachmentItem';
import styles from './photoList.module.scss';

const isUploadedFile = (data: any) => {
    return data && data.file && data.file instanceof File;
};

const getUploadedFileUrl = (file: any) => {
    return URL.createObjectURL(file);
};

const getExistsFileUrl = (file: any) => {
    return getPathToPhoto(file.path);
};

const ExistsFile = ({file, index, onDelete}: any) => {
    return <AttachmentItem index={index} fileName={file.name} fileUrl={getExistsFileUrl(file)} onDelete={onDelete} />;
};

const UploadedFile = ({file, index, onDelete}: any) => {
    return <AttachmentItem index={index} fileName={file.name} fileUrl={getUploadedFileUrl(file)} onDelete={onDelete} />;
};

const ListAttachments = (props: any): JSX.Element => {
    const {mode, form, name, attachmentName, uploadTitle, push, remove} = props;
    const attachments = lodashGet(form.values, name) || [];

    const handleAddNewFile = (file: File): void => {
        const addedAttachments = lodashGet(form.values.addedAttachments, attachmentName) || [];
        if (mode === modes.MODE_UPDATE) {
            form.setFieldValue(`addedAttachments.${attachmentName}`, [...addedAttachments, {file}]);
        }
    };
    const onFileSelect = (event: any) => {
        const file = event.target && event.target.files[0];
        if (file) {
            push({
                file,
                url: getUploadedFileUrl(file),
            });
            handleAddNewFile(file);
        }
    };
    const deleteUploadedFile = (index: any) => {
        remove(index);
    };
    const deleteExistsFile = (index: any, file: any) => {
        const removedAttachments = lodashGet(form.values.removedAttachments, attachmentName) || [];
        if (mode === modes.MODE_UPDATE) {
            form.setFieldValue(`removedAttachments.${attachmentName}`, [...removedAttachments, {file}]);
        }
        remove(index);
    };
    const photosItems = attachments
        .map((attachmentItem: any, index: any) => {
            if (isUploadedFile(attachmentItem)) {
                return (
                    <UploadedFile
                        key={attachmentItem.file.name}
                        file={attachmentItem.file}
                        index={index}
                        onDelete={() => deleteUploadedFile(index)}
                    />
                );
            }

            return (
                <ExistsFile
                    key={attachmentItem.path}
                    file={attachmentItem}
                    index={index}
                    onDelete={() => deleteExistsFile(index, attachmentItem)}
                />
            );
        })
        .filter(Boolean);

    return (
        <div className={styles.upload}>
            {photosItems.length > 0 && <div className="photo-list">{photosItems}</div>}
            <div className="upload-button">
                <Icon type="download" /> {uploadTitle}
                <input onChange={onFileSelect} type="file" />
            </div>
        </div>
    );
};

export default ListAttachments;
