import React from 'react';
import {useSelector} from 'react-redux';

import * as modes from 'constants/modes';
import {isUserLoggedIn} from 'app/reducers/commonData/selectors';

import Booking from './Booking';
import Create from './Create';
import Update from './Update';
import Delete from './Delete';

interface OwnProps {
    mode: string;
    data: any;
    onClose(): void;
    onSubmit(fields: any): void;
}

const Gift = ({mode, data, onClose, onSubmit}: OwnProps): JSX.Element | null => {
    const isAdmin = useSelector(isUserLoggedIn);

    if (mode === modes.MODE_CREATE) {
        return <Create mode={mode} isAdmin={isAdmin} onClose={onClose} onSubmit={onSubmit} />;
    }

    if (mode === modes.MODE_UPDATE) {
        return <Update gift={data} isAdmin={isAdmin} mode={mode} onClose={onClose} onSubmit={onSubmit} />;
    }

    if (mode === modes.MODE_DELETE) {
        return <Delete gift={data} onClose={onClose} onSubmit={onSubmit} />;
    }

    if (mode === modes.MODE_BOOKING) {
        return <Booking gift={data} onClose={onClose} onSubmit={onSubmit} />;
    }

    return null;
};

export default Gift;
