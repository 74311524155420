import React, {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Col, Row} from 'antd';

import {Gift, PersonWhoBookedGift} from 'types/index';

import * as statuses from 'constants/statuses';
import useShowModalsActions from 'hooks/useShowModalsActions';

import BreadCrumbs from './components/BreadCrumbs';
import GiftItem from './components/GiftItem';
import SearchForGuest from './components/SearchForGuest';
import Pagination from './components/Pagination';

import {getListGifts, getPagination, getSearch} from './selectors';
import * as actions from './actions/guestActions';
import {changePaginationPage, changeSearchParams, clearSearchParams} from './actions/commonActions';

const GiftsForGuestRole = (): JSX.Element => {
    const gifts = useSelector(getListGifts);
    const searchParams = useSelector(getSearch);
    const pagination = useSelector(getPagination);
    const {showBookGiftModal} = useShowModalsActions();
    const dispatch = useDispatch();
    const searchByFreeStatus = useCallback(
        isNeedSearchByFreeStatus => {
            dispatch(changeSearchParams({status: isNeedSearchByFreeStatus ? statuses.GIFT_STATUS_FREE : ''}));
        },
        [dispatch],
    );
    const searchByMonthHandler = useCallback(
        data => {
            dispatch(changeSearchParams({month: data.month}));
        },
        [dispatch],
    );
    const clearSearchParamsHandler = useCallback(() => {
        dispatch(clearSearchParams());
    }, [dispatch]);
    const showBookGiftModalHandler = useCallback(
        (gif: Gift) => {
            showBookGiftModal(gif, (bookedPerson: PersonWhoBookedGift): void => {
                dispatch(actions.bookGift(gif, bookedPerson));
            });
        },
        [dispatch, showBookGiftModal],
    );
    const changePaginationPageHandler = useCallback(
        (nextPage: number) => {
            dispatch(changePaginationPage(nextPage));
        },
        [dispatch],
    );
    useEffect(() => {
        dispatch(actions.fetchGifts({searchParams, pagination: {page: pagination.page, size: pagination.size}}));
    }, [dispatch, searchParams, pagination.page, pagination.size]);
    return (
        <div>
            <BreadCrumbs />
            <h1>Список детей</h1>
            <SearchForGuest
                searchParams={searchParams}
                onSearchFreeGifts={searchByFreeStatus}
                onClearParams={clearSearchParamsHandler}
                onSearchByMonth={searchByMonthHandler}
            />
            <Row className="gift-list-row" type="flex">
                {gifts.map((gift: Gift) => (
                    <Col lg={6} md={12} xs={24} key={gift._id}>
                        <GiftItem gift={gift} isAdmin={false} onBookItem={showBookGiftModalHandler} />
                    </Col>
                ))}
            </Row>
            {gifts.length > 0 && (
                <Pagination
                    allPages={pagination.pagesCount}
                    currentPage={pagination.page}
                    onPageChange={changePaginationPageHandler}
                />
            )}
        </div>
    );
};

export default GiftsForGuestRole;
