import React from 'react';
import {useSelector} from 'react-redux';

import {Icon} from 'antd';

import {getShowLoadSpinner} from 'app/reducers/commonData/selectors';

import './style.css';

const LoaderSpinner = (): JSX.Element | null => {
    const showLoadSpinner = useSelector(getShowLoadSpinner);
    return showLoadSpinner ? (
        <div className="loading">
            <Icon type="loading" />
        </div>
    ) : null;
};

export default LoaderSpinner;
