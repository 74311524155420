import React from 'react';
import {useSelector} from 'react-redux';

import {isUserLoggedIn} from 'app/reducers/commonData/selectors';

import GiftsForAdminRole from './GiftsForAdminRole';
import GiftsForGuestRole from './GiftsForGuestRole';

import styles from './gift.module.scss';

const Gifts = (): JSX.Element => {
    const isCurrentUserLoggedIn = useSelector(isUserLoggedIn);
    return <div className={styles.list}>{isCurrentUserLoggedIn ? <GiftsForAdminRole /> : <GiftsForGuestRole />}</div>;
};

export default Gifts;
