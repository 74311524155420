import React from 'react';
import {connect} from 'react-redux';
import isFunction from 'lodash/isFunction';

import handler from 'components/modals/handler';
import * as appActions from 'app/actions';

import GiftModal from 'components/modals/Gift';
import ReservedGiftWarning from 'components/modals/ReservedGiftWarning';
import BookGiftConfirm from 'components/modals/BookGiftConfirm';
import BookGiftSuccess from 'components/modals/BookGiftSuccess';
import ConfirmGiftChangeStatusToFree from 'components/modals/ConfirmGiftChangeStatusToFree';
import GiftCreationSuccess from 'components/modals/CreationGiftSuccess';
import GiftCreationError from 'components/modals/CreationGiftError';
import GiftUpdateError from 'components/modals/UpdateGiftError';

import * as modalNames from 'constants/modals';

const ModalContainer = ({modalData, onClose}: any) => {
    if (!modalData) {
        return null;
    }
    const {name, mode, data, closeBeforeSubmit = true} = modalData;
    const onSubmitHandler = handler.get();
    const onSubmit = (fields: any) => {
        if (closeBeforeSubmit) {
            onClose();
        }
        if (isFunction(onSubmitHandler)) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            onSubmitHandler(fields);
        }
    };

    if (name === modalNames.GIFT_MODAL) {
        return <GiftModal mode={mode} data={data} onSubmit={onSubmit} onClose={onClose} />;
    }

    if (name === modalNames.GIFT_BOOKING_CONFIRM_MODAL) {
        return <BookGiftConfirm data={data} onSubmit={onSubmit} onClose={onClose} />;
    }

    if (name === modalNames.BOOKING_SUCCESS_MODAL) {
        return <BookGiftSuccess data={data} onSubmit={onSubmit} onClose={onClose} />;
    }

    if (name === modalNames.RESERVED_MODAL) {
        return <ReservedGiftWarning data={data} onSubmit={onSubmit} onClose={onClose} />;
    }

    if (name === modalNames.CONFIRM_CHANGE_STATUS_TO_FREE_MODAL) {
        return <ConfirmGiftChangeStatusToFree data={data} onSubmit={onSubmit} onClose={onClose} />;
    }

    if (name === modalNames.GIFT_CREATION_SUCCESS_MODAL) {
        return <GiftCreationSuccess data={data} onSubmit={onSubmit} onClose={onClose} />;
    }

    if (name === modalNames.GIFT_CREATION_ERROR_MODAL) {
        return <GiftCreationError data={data} onSubmit={onSubmit} onClose={onClose} />;
    }

    if (name === modalNames.GIFT_UPDATE_ERROR_MODAL) {
        return <GiftUpdateError data={data} onSubmit={onSubmit} onClose={onClose} />;
    }

    return null;
};

export default connect(
    state => {
        return {
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            modalData: state.app.modalData,
        };
    },
    dispatch => {
        return {
            onClose: () => dispatch(appActions.hideModal()),
        };
    },
)(ModalContainer);
