import React from 'react';
import {Checkbox} from 'antd';

interface OwnProps {
    checked: boolean;

    onChange(checked: boolean): void;
}

const ConfirmTermsOfConditionsCheckbox = ({checked, onChange}: OwnProps): JSX.Element | null => {
    const label =
        'Организатор проекта оставляет за собой право подтверждать, отменять, редактировать, удалять заявки без предварительного обсуждения c участником.';
    return (
        <div>
            <Checkbox checked={checked} onChange={event => onChange(event.target.checked)}>
                {label}
            </Checkbox>
        </div>
    );
};

export default ConfirmTermsOfConditionsCheckbox;
