import React from 'react';
import {Route, Switch} from 'react-router-dom';

import IndexPage from 'pages/Index';
import LoginPage from 'pages/Login';
import GiftsPage from 'pages/Gifts';
import RequisitesPage from 'pages/Requisites';
import NotFoundPage from 'pages/NotFound';

import {userIsNotAuthenticatedRedir, userIsAuthenticatedRedir} from './auth';

const LoginPageProtected = userIsNotAuthenticatedRedir(LoginPage);
const GiftsPageProtected = userIsAuthenticatedRedir(GiftsPage);

export default () => (
    <Switch>
        <Route exact path="/" component={IndexPage} />

        <Route exact path="/gifts" component={GiftsPage} />

        <Route exact path="/admin" component={GiftsPageProtected} />

        <Route exact path="/requisites" component={RequisitesPage} />

        <Route exact path="/login" component={LoginPageProtected} />

        <Route component={NotFoundPage} />
    </Switch>
);
