import * as types from '../actionTypes';
import {SearchParams} from '../types';

export function changeSearchParams(searchParams: SearchParams): any {
    return {
        type: types.SELECTED_SEARCH_PARAMS_CHANGED,
        payload: searchParams,
    };
}

export function clearSearchParams(): any {
    return {
        type: types.SELECTED_SEARCH_PARAMS_CLEARED,
    };
}

export function changePaginationPage(nextPage: number): any {
    return {
        type: types.SELECTED_PAGINATION_CHANGED,
        payload: {nextPage},
    };
}
