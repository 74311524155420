import React from 'react';
import {getIn} from 'formik/';
import {DatePicker as DatePickerFromAntd, Form, ConfigProvider} from 'antd/lib';
import ruRU from 'antd/es/locale/ru_RU';
import moment from 'moment/moment';
import 'moment/min/locales';

interface OwnProps {
    field: any;
    form: any;
    placeholder: string;
}

const DatePicker = ({field, form, placeholder}: OwnProps): JSX.Element | null => {
    const fieldName = field.name;
    const currentValue = getIn(form.values, fieldName);
    const currentDateValue = currentValue ? moment(currentValue).locale('ru') : undefined;
    const validationError = getIn(form.errors, field.name);
    const isTouched = Boolean(form.touched[field.name]);
    const isInvalid = Boolean(isTouched && validationError) || Boolean(validationError);
    const handleChange = (date: any, dateString: any): void => {
        form.setFieldValue(fieldName, dateString ? moment(dateString, 'DD.MM.YYYY') : undefined);
    };
    const dateFormatList = ['DD.MM.YYYY'];
    return (
        <Form.Item
            validateStatus={isInvalid ? 'error' : ''}
            hasFeedback={isInvalid}
            help={isInvalid ? validationError : ''}
        >
            <ConfigProvider locale={ruRU}>
                <DatePickerFromAntd
                    value={currentDateValue}
                    onChange={handleChange}
                    placeholder={placeholder}
                    format={dateFormatList}
                />
            </ConfigProvider>
        </Form.Item>
    );
};

export default DatePicker;
