import React from 'react';

import {Breadcrumb} from 'antd';
import {Link} from 'react-router-dom';

const BreadCrumbs = (): JSX.Element | null => {
    return (
        <Breadcrumb>
            <Breadcrumb.Item>
                <Link to="/">Главная</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Список детей</Breadcrumb.Item>
        </Breadcrumb>
    );
};

export default BreadCrumbs;
