import React, {useMemo} from 'react';
import {Select} from 'antd';

const monthOptions = [
    {value: '1', label: 'Январь'},
    {value: '2', label: 'Февраль'},
    {value: '3', label: 'Март'},
    {value: '4', label: 'Апрель'},
    {value: '5', label: 'Май'},
    {value: '6', label: 'Июнь'},
    {value: '7', label: 'Июль'},
    {value: '8', label: 'Август'},
    {value: '9', label: 'Сентябрь'},
    {value: '10', label: 'Октябрь'},
    {value: '11', label: 'Ноябрь'},
    {value: '12', label: 'Декабрь'},
    {value: null, label: 'за весь год'},
];

const MonthDropDown = ({value, onChange}: any): JSX.Element => {
    const selectedOption = useMemo(() => {
        return monthOptions.find((item: any) => item.value === value);
    }, [value]);
    const handleChange = (selectedValue: any): void => {
        onChange(selectedValue);
    };

    return (
        <>
            <Select value={selectedOption?.value} onChange={handleChange} style={{width: 130}}>
                {monthOptions.map((item: any, index: number) => (
                    <Select.Option key={index} value={item.value}>
                        {item.label}
                    </Select.Option>
                ))}
            </Select>
        </>
    );
};

export default MonthDropDown;
