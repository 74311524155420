import React from 'react';
import {Formik, Field} from 'formik';
import {Form as AntdForm, Button} from 'antd';
import * as Yup from 'yup';

import FormTextInput from 'components/form/TextInput';

export interface FormValues {
    username: string | undefined;
    password: string | undefined;
}

interface FormProps {
    onSubmit(fields: FormValues): void;
}

const validationSchema = Yup.object().shape<FormValues>({
    username: Yup.string().required('Введите логин'),
    password: Yup.string().required('Введите пароль'),
});

const FormContent = ({formProps: {handleSubmit}}: any): JSX.Element => {
    return (
        <AntdForm onSubmit={handleSubmit}>
            <Field
                maxLength={50}
                label="Username"
                name="username"
                type="text"
                component={FormTextInput}
                required={true}
            />
            <Field
                maxLength={50}
                label="Password"
                name="password"
                type="password"
                component={FormTextInput}
                required={true}
            />
            <br />
            <Button htmlType="submit" className="login-form-button">
                Log in
            </Button>
        </AntdForm>
    );
};

const LoginForm = ({onSubmit}: FormProps): JSX.Element => (
    <Formik initialValues={{username: '', password: ''}} validationSchema={validationSchema} onSubmit={onSubmit}>
        {props => <FormContent formProps={props} />}
    </Formik>
);

export default LoginForm;
