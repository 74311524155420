import React from 'react';
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import styles from './attachmentItem.module.scss';

const AttachmentItem = props => {
    const {fileName, fileUrl, index, onDelete} = props;
    return (
        <div className={styles.item} key={index}>
            <div className={styles.grid}>
                <span onClick={onDelete} className="close-btn">
                    <FontAwesomeIcon icon={faTimes} />
                </span>
                {/* eslint-disable-next-line react/jsx-no-target-blank */}
                <a href={fileUrl} target="_blank">
                    <img src={fileUrl} alt="" />
                </a>
            </div>
            <div className="file-name-tooltip">
                <div className="file-name" id={`tooltip-${index}`}>
                    {fileName}
                </div>
            </div>
        </div>
    );
};

export default AttachmentItem;
