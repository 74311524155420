import React from 'react';

import {Layout} from 'antd/lib';
import Header from '../layout/header/index';
import Footer from '../layout/footer/index';
import Main from '../layout/main/index';

import './css/custom.scss';

const App: React.FC = () => {
    return (
        <div className="volunteer">
            <Layout>
                <Header />
                <Main />
                <Footer />
            </Layout>
        </div>
    );
};

export default App;
