import {useDispatch} from 'react-redux';

import {Gift} from 'types';

import {showModal} from 'app/actions';
import * as modalNames from 'constants/modals';
import * as modes from 'constants/modes';

export default function() {
    const dispatch = useDispatch();
    return {
        showCreateGiftModal: (onSubmit: any) => {
            const params = {
                name: modalNames.GIFT_MODAL,
                data: {},
                mode: modes.MODE_CREATE,
                onSubmit,
            };
            dispatch(showModal(params));
        },
        showEditGiftModal: (gift: Gift, onSubmit: any) => {
            const params = {
                name: modalNames.GIFT_MODAL,
                data: gift,
                mode: modes.MODE_UPDATE,
                onSubmit,
            };
            dispatch(showModal(params));
        },
        showBookGiftModal: (gift: Gift, onSubmit: any) => {
            const params = {
                name: modalNames.GIFT_MODAL,
                data: gift,
                mode: modes.MODE_BOOKING,
                onSubmit,
            };
            dispatch(showModal(params));
        },
        showDeleteGiftModal: (gift: Gift, onSubmit: any) => {
            const params = {
                name: modalNames.GIFT_MODAL,
                data: gift,
                mode: modes.MODE_DELETE,
                onSubmit,
            };
            dispatch(showModal(params));
        },
    };
}
