import {applyMiddleware, compose, createStore} from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';

import {loadFromLocalStorage} from 'utils';
import rootReducer from 'app/reducers';
import auth from 'middlewares/auth';
import modalClose from 'middlewares/modalClose';

const appReducer = rootReducer(loadFromLocalStorage('auth'));
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middleware =
    process.env.NODE_ENV === 'development'
        ? applyMiddleware(thunk, logger, auth, modalClose)
        : applyMiddleware(thunk, auth, modalClose);
export default createStore(appReducer, composeEnhancers(middleware));
