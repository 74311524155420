import * as types from 'app/actions/actionTypes';
import {saveToLocalStorage} from '../utils';

export default (store: any) => (next: any) => (action: any) => {
    if (action.type === types.USER_LOGGED_IN) {
        saveToLocalStorage('auth', {isLoggedIn: true});
    } else if (action.type === types.USER_LOGGED_OUT) {
        window.localStorage.removeItem('auth');
    }

    return next(action);
};
