import {isModalOpened} from 'app/reducers/commonData/selectors';
import {hideModal} from 'app/actions';

export default (store: any): any => {
    window.addEventListener('popstate', function() {
        if (isModalOpened(store.getState())) {
            store.dispatch(hideModal());
        }
    });
    return (next: any) => {
        return (action: any) => {
            return next(action);
        };
    };
};
