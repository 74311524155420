import React from 'react';
import {Modal, Button} from 'antd';

const BookGiftSuccessModal = ({data, onClose}: any): JSX.Element => {
    const {gift} = data;
    return (
        <Modal
            visible={true}
            closable={false}
            title="Спасибо, что стали волшебником!"
            footer={[
                <div key="2" />,
                <Button key="1" color="primary" onClick={onClose} className="custom-btn custom-btn__consent">
                    OK
                </Button>,
            ]}
        >
            <div>
                {' '}
                Вы забронировали подарок для <strong>{gift.child.name}</strong>.<br />
                Благодарим, что Вы с нами. Организатор с Вами свяжется, если у Вас есть вопросы напишите нам.
            </div>
        </Modal>
    );
};

export default BookGiftSuccessModal;
