import React from 'react';
import {Formik, Field} from 'formik';
import {Form as AntdForm, Button, Row, Col} from 'antd';

import * as Yup from 'yup';

import FormTextInput from 'components/form/TextInput';
import FormTextArea from 'components/form/TextArea';

export interface FormValues {
    name: string | undefined;
    phone: string | undefined;
    comment: string | undefined;
}

interface FormProps {
    onClose(): void;
    onSubmit(fields: FormValues): void;
}

const phoneMask = [
    '+',
    '3',
    '8',
    ' ',
    '(',
    /[0-9]/,
    /\d/,
    /\d/,
    ')',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
];

const validationSchema = Yup.object().shape<FormValues>({
    name: Yup.string().required('Обязательное поле'),
    phone: Yup.string().required('Обязательное поле'),
    comment: Yup.string(),
});

const FormContent = ({formProps: {handleSubmit}, onClose}: any): JSX.Element => {
    return (
        <AntdForm onSubmit={handleSubmit} className="form">
            <div className="form__main-block">
                <Field
                    maxLength={50}
                    label="Ваше Имя Фамилия"
                    name="name"
                    component={FormTextInput}
                    required={true}
                    placeholder=""
                />
                <Field
                    maxLength={50}
                    name="phone"
                    label="Телефон"
                    mask={phoneMask}
                    component={FormTextInput}
                    placeholder="+38 (___) ___ __ __"
                />
                <Field label="Комментарий, ссылка на профиль в соцсетях" name="comment" component={FormTextArea} />
                <p>
                    К основному подарку по желанию можно приложить сладости на ваше усмотрение. Дети очень любят сладкое
                    и рады всему.
                </p>
            </div>
            <div className="form__info-block">
                <h3>Отправка подарка Новой Почтой</h3>
                <div className="form__info-block_address">
                    <Row>
                        <Col md={6}>Адрес</Col>
                        <Col md={16}>г. Николаев, отделение №6</Col>
                    </Row>
                    <Row>
                        <Col md={6}>Получатель</Col>
                        <Col md={16}>
                            Ткаченко Валентина Валентиновна
                            <br />
                            тел. +38 (097) 239 72 70
                        </Col>
                    </Row>
                </div>
                <div className="form__info-block_request">
                    Если вы отправляете посылку Новой Почтой, просим оплатить услуги за пересылку.
                </div>
            </div>
            <div className="form__footer-block">
                <Row type="flex" justify="space-between">
                    <Button onClick={onClose} className="custom-btn custom-btn__cancel">
                        Отменить
                    </Button>
                    <Button type="primary" htmlType="submit" className="custom-btn custom-btn__action">
                        Забронировать
                    </Button>
                </Row>
            </div>
        </AntdForm>
    );
};

const Form = ({onSubmit, onClose}: FormProps): JSX.Element => (
    <Formik
        initialValues={{name: '', phone: '', socialLink: '', comment: ''}}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
    >
        {props => <FormContent formProps={props} onClose={onClose} />}
    </Formik>
);

export default Form;
