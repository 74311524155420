import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Icon} from 'antd';

const CustomPagination = ({allPages, currentPage, onPageChange}: any) => {
    if (!allPages === undefined || currentPage === undefined) {
        return null;
    }

    const onSelectPrevPage = () => {
        const prevPage = currentPage - 1;
        if (prevPage >= 0) {
            onPageChange(prevPage);
        }
    };
    const onSelectNextPage = () => {
        const nextPage = currentPage + 1;
        if (nextPage < allPages) {
            onPageChange(nextPage);
        }
    };

    const displayFirstPage = () => {
        const firstPage = 0;
        return (
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
            <li
                onClick={() => onPageChange(firstPage)}
                className={`ant-pagination-item ant-pagination-item-${firstPage + 1} ${currentPage === firstPage &&
                    'ant-pagination-item-active'}`}
            >
                <span>{firstPage + 1}</span>
            </li>
        );
    };

    const displayLeftEllipsis = () => {
        const activePage = currentPage;
        const minPagesForEllipsis = 4;

        if (activePage < minPagesForEllipsis) {
            return null;
        }
        return '...';
    };

    const displayMiddleContent = () => {
        const firstPage = 0;
        const lastPage = allPages - 1;
        const OFFSET_FROM_LEFT = 2;
        const counterStart = currentPage - OFFSET_FROM_LEFT;
        const maxElement = 4;
        const list = [];

        for (let i = 0; i < maxElement; i++) {
            const counterPage = counterStart + i;
            const item = (
                // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                <li
                    key={counterPage}
                    onClick={() => onPageChange(counterPage)}
                    className={`ant-pagination-item ant-pagination-item-${firstPage + 1} ${currentPage ===
                        counterPage && 'ant-pagination-item-active'}`}
                >
                    <span>{counterPage + 1}</span>
                </li>
            );

            if (counterPage > firstPage && counterPage < lastPage) {
                list.push(item);
            }
        }
        return list;
    };

    const displayRightEllipsis = () => {
        const difference = allPages - currentPage;
        const minDifferenceForEllipsis = 4;

        if (difference < minDifferenceForEllipsis) {
            return null;
        }
        return '...';
    };

    const displayLastPage = () => {
        const firstPage = 0;
        const lastPage = allPages - 1;
        if (firstPage !== lastPage) {
            return (
                // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                <li
                    onClick={() => onPageChange(lastPage)}
                    className={`ant-pagination-item ant-pagination-item-${lastPage + 1} ${currentPage === lastPage &&
                        'ant-pagination-item-active'}`}
                >
                    <span>{lastPage + 1}</span>
                </li>
            );
        }
    };
    return (
        <>
            <ul className="ant-pagination">
                <li title="Previous Page" className=" ant-pagination-prev" aria-disabled="false">
                    <span className="ant-pagination-item-link" onClick={onSelectPrevPage}>
                        <Icon type="left" />
                    </span>
                </li>
                {Array.from({length: allPages}, (_, index) => index).map(page => {
                    if (currentPage === page) {
                        return (
                            <Fragment key={page}>
                                {displayFirstPage()}
                                {displayLeftEllipsis()}
                                {displayMiddleContent()}
                                {displayRightEllipsis()}
                                {displayLastPage()}
                            </Fragment>
                        );
                    }
                    return null;
                })}
                <li title="Next Page" className=" ant-pagination-next" aria-disabled="false">
                    <span className="ant-pagination-item-link" onClick={onSelectNextPage}>
                        <Icon type="right" />
                    </span>
                </li>
            </ul>
        </>
    );
};

CustomPagination.propTypes = {
    allPages: PropTypes.number,
    currentPage: PropTypes.number,
    onPageChange: PropTypes.func.isRequired,
};

CustomPagination.defaultProps = {
    allPages: 0,
    currentPage: 0,
};

export default CustomPagination;
