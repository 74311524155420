import React from 'react';
import {Modal, Button} from 'antd';

const GiftCreationSuccess = ({onSubmit}: any): JSX.Element => {
    return (
        <Modal
            visible={true}
            closable={false}
            title="Успешная отправка"
            footer={[
                <div key="2" />,
                <Button key="1" color="primary" className="custom-btn custom-btn__consent" onClick={onSubmit}>
                    OK
                </Button>,
            ]}
        >
            <div>Ваша заявка успешно отправлена!</div>
        </Modal>
    );
};

export default GiftCreationSuccess;
