import React, {useState} from 'react';
import Lightbox from 'react-image-lightbox';

import {getPathToPhoto} from 'utils/index';

import 'react-image-lightbox/style.css';

const Gallery = ({photos, children}: any) => {
    const [isOpen, setOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);
    const getNextPhotoSrc = () => {
        const path = getPathToPhoto(photos[photoIndex + 1]?.path);
        return path;
    };
    const getPrevPhotoSrc = () => {
        const path = getPathToPhoto(photos[photoIndex - 1]?.path);
        return path;
    };
    const closeGallery = () => {
        setOpen(false);
        setPhotoIndex(0);
    };

    if (photos.length === 0) {
        return null;
    }
    const currentSrc = photos[photoIndex];
    return (
        <>
            <span className="custom-link" onClick={() => setOpen(true)}>
                {children}
            </span>
            {isOpen && (
                <Lightbox
                    mainSrc={getPathToPhoto(currentSrc.path) as string}
                    nextSrc={getNextPhotoSrc()}
                    prevSrc={getPrevPhotoSrc()}
                    toolbarButtons={[
                        <div className="gallery-counter">
                            <strong>
                                {photoIndex + 1} / {photos.length}
                            </strong>{' '}
                            photo(s)
                        </div>,
                    ]}
                    onMovePrevRequest={() => setPhotoIndex(photoIndex - 1)}
                    onMoveNextRequest={() => setPhotoIndex(photoIndex + 1)}
                    onCloseRequest={closeGallery}
                />
            )}
        </>
    );
};

export default Gallery;
